import React, { useState } from 'react';

import {
  postVerifyEmail as postVerifyEmailApi,
} from '@/apis/Auth/authApi';

import { VerifyViewer } from './verifyViewer';

const appUrl = process.env.REACT_APP_URL;

// Function to set the access token in local storage
export const handleSetAccessToken = (newValue) => {
  // Set the value in local storage
  localStorage.setItem('access_token', newValue);
};

// Function to set the refresh token in local storage
export const handleSetRefreshToken = (newValue) => {
  // Set the value in local storage
  localStorage.setItem('refresh_token', newValue);
};

export function VerifyController({
}) {
  // const {
  // } = props;

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState("");

  const handleTokenChange = (event) => {
    console.log(event);
    const token = event.target.value
    setToken(token);
  }

  async function verifyEmailToken(event) {
    event.preventDefault();

    const data = {
      'token': token,
    };

    try {
      const response = await postVerifyEmailApi(data);

      const accessToken = response.data.auth.access_token;
      const refreshToken = response.data.auth.refresh_token;
      //const refreshedAt = response.data.auth.updated_at;

      handleSetAccessToken(accessToken);
      handleSetRefreshToken(refreshToken);

      window.location = `${appUrl}/login`;
    } catch (error) {
      console.error(error);
      setErrors(error);
    }
  };


  return <VerifyViewer
    verifyEmailToken={verifyEmailToken}
    handleTokenChange={handleTokenChange}
    token={token}
  />
}