import React from 'react';

import Container from 'react-bootstrap/Container';

import VerifyEmail from './components/verifyEmail';

export function VerifyViewer({
  verifyEmailToken,
  handleTokenChange,
  token
}) {
  return (
    <Container
      className='vh-100'
      fluid="true"
    >
      <VerifyEmail
        verifyEmailToken={verifyEmailToken}
        handleTokenChange={handleTokenChange}
        token={token}
      />
    </Container>
  )
}