const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export const getTemplates = async (role) => {
  const payload = {};
  payload.role_id = role['id'];
  try {
    const result = await fetch(`${apiUrl}/formatter/template/list`, {
      method: "POST",
      headers: {
        "x-api-key": `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    const templates = data.data.data;

    return templates;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch templates", error);
  }
}

export const getResumes = async (role, resumeIDs = undefined) => {
  const payload = {
    role_id: role['id'],
  };

  if (resumeIDs !== undefined) {
    payload.ids = resumeIDs;
  }

  try {
    const result = await fetch(`${apiUrl}/formatter/resume/list`, {
      method: "POST",
      headers: {
        'x-api-key': `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    const resumes = data.data.data;

    return resumes;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch resumes", error);
  }
}

export const getResumeByIDs = async (role, resumeIDs) => {
  const payload = {
    role_id: role['id'],
    ids: resumeIDs,
    oldest_creation: 5 * 60 // limit only resumes created in the last 5 minutes
  };

  try {
    const result = await fetch(`${apiUrl}/formatter/resume/list/ids`, {
      method: "POST",
      headers: {
        'x-api-key': `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    const resumes = data.data.data;

    return resumes;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch resumes", error);
  }
}

export const addRole = async (formData) => {
  const payload = {};
  payload.data = formData;

  try {
    const result = await fetch(`${apiUrl}/formatter/role/add`, {
      method: "POST",
      headers: {
        'x-api-key': `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to add role", error);
  }
}

export const deleteRole = async (formData) => {
  const payload = {};
  payload.id = formData.id;

  try {
    const result = await fetch(`${apiUrl}/formatter/role/delete`, {
      method: "POST",
      headers: {
        'x-api-key': `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete role", error);
  }
}

export const deleteTemplate = async (formData) => {
  const payload = {};
  payload.id = formData.id;

  try {
    const result = await fetch(`${apiUrl}/formatter/template/delete`, {
      method: "POST",
      headers: {
        'x-api-key': `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete role", error);
  }
}

export const deleteResume = async (formData) => {
  const payload = {};
  payload.id = formData.id;

  try {
    const result = await fetch(`${apiUrl}/formatter/resume/delete`, {
      method: "POST",
      headers: {
        'x-api-key': `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const data = await result.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete role", error);
  }
}

export const getRoles = async () => {
  try {
    const result = await fetch(`${apiUrl}/formatter/role/list`, {
      method: "GET",
      headers: {
        'x-api-key': `${apiKey}`,
      },
      mode: 'cors',
    });

    const data = await result.json();
    const roles = data.data.data.data;

    return roles;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch resumes", error);
  }
}