import React, { useState } from 'react';

import {
  postLogin as postLoginApi,
  guardTest as guardTestApi,
  postSignup as postSignupApi,
} from '@/apis/Auth/authApi';

import { LoginViewer } from './loginViewer';

const appUrl = process.env.REACT_APP_URL;

// Function to set the access token in local storage
export const handleSetAccessToken = (newValue) => {
  // Set the value in local storage
  localStorage.setItem('access_token', newValue);
};

// Function to set the refresh token in local storage
export const handleSetRefreshToken = (newValue) => {
  // Set the value in local storage
  localStorage.setItem('refresh_token', newValue);
};

export function LoginController(props) {
  const {
    initLogin = false,
    loginError = '',
    errors
  } = props;

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  // const [errors, setErrors] = useState({})
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSignup, setSignup] = useState(initLogin);
  const [authenticating, setAuthenticating] = useState(false);


  const validateEmail = () => {
    const currentErrors = errors
    delete currentErrors.email

    if (email.trim() === "") {
      currentErrors.email = "Email field is required"
    }

    if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      currentErrors.email = "Email field is invalid"
    }

    // setErrors(currentErrors)

    return !!currentErrors.email
  }

  const handleEmailChange = (event) => {
    const email = event.target.value
    setEmail(email);

    if (!!hasSubmitted) {
      validateEmail()
    }
  }

  const validatePassword = () => {
    const currentErrors = errors
    delete currentErrors.password

    if (password.trim() === "") {
      currentErrors.password = "Password field is required"
    }

    // setErrors(currentErrors)

    return !!currentErrors.password
  }

  const handlePasswordChange = (event) => {
    const password = event.target.value
    setPassword(password)

    if (!!hasSubmitted) {
      validatePassword()
    }
  }

  const validateFirstName = () => {
    const currentErrors = errors
    delete currentErrors.firstName

    if (firstName.trim() === "") {
      currentErrors.firstName = "First name field is required"
    }

    // setErrors(currentErrors)

    return !!currentErrors.firstName
  }

  const handleFirstNameChange = (event) => {
    const firstName = event.target.value
    setFirstName(firstName);

    if (!!hasSubmitted) {
      validateFirstName()
    }
  }

  const validateLastName = () => {
    const currentErrors = errors
    delete currentErrors.lastName

    if (lastName.trim() === "") {
      currentErrors.lastName = "Last name field is required"
    }

    // setErrors(currentErrors)

    return !!currentErrors.lastName
  }

  const handleLastNameChange = (event) => {
    const lastName = event.target.value
    setLastName(lastName);

    if (!!hasSubmitted) {
      validateLastName()
    }
  }

  const handleSubmitForm = async () => {
    setHasSubmitted(true)
    setIsSubmitting(true)

    const emailIsInvalid = validateEmail()
    const passwordIsInvalid = validatePassword()

    if (emailIsInvalid || passwordIsInvalid) {
      setIsSubmitting(false)
      return
    }

    const data = {
      email,
      password,
      firstName,
      lastName,
    }

    const currentErrors = errors
    delete currentErrors.form

    try {
      const response = await login(data)

      if (response.status.code === 403) {
        currentErrors.form = response.status.error
        // setErrors(currentErrors);
        return
      }

      const auth = response.auth

      // setErrors(currentErrors)
    } catch (error) {
      currentErrors.form = error
      // setErrors(currentErrors)
    }
  }

  function toggleSignup() {
    setSignup(!isSignup);
  };

  async function signup(event) {
    event.preventDefault();

    const data = {
      'email': email,
      'password': password,
      'first_name': firstName,
      'last_name': lastName,
    };

    try {
      const response = await postSignupApi(data);

      window.location = `${appUrl}/verify`;
    } catch (error) {
      console.error(error);
    }
  };

  async function login(event) {
    event.preventDefault();

    const data = {
      'email': email,
      'password': password,
    };

    try {
      setAuthenticating(true);
      const response = await postLoginApi(data);

      const accessToken = response.data.auth.access_token;
      const refreshToken = response.data.auth.refresh_token;
      const refreshedAt = response.data.auth.updated_at;

      handleSetAccessToken(accessToken);
      handleSetRefreshToken(refreshToken);

      window.location = `${appUrl}/format`;
      setAuthenticating(false);
    } catch (error) {
      // setErrors(error);
      setAuthenticating(false);
    }
  };

  async function guardCheck(event) {
    event.preventDefault();

    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    const payload = {
      'access_token': accessToken,
      'refresh_token': refreshToken,
    };

    try {
      const response = await guardTestApi(payload);
    } catch (error) {
      console.error(error);
    }
  };

  return <LoginViewer
    guardCheck={guardCheck}
    email={email}
    handleEmailChange={handleEmailChange}
    password={password}
    handlePasswordChange={handlePasswordChange}
    firstName={firstName}
    handleFirstNameChange={handleFirstNameChange}
    lastName={lastName}
    handleLastNameChange={handleLastNameChange}
    isSubmitting={isSubmitting}
    handleSubmitForm={handleSubmitForm}
    // errors={errors}
    isSignup={isSignup}
    signup={signup}
    toggleSignup={toggleSignup}
    login={login}
    loginError={loginError}
    authenticating={authenticating}
  />
}