const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export const postLogin = async (postData) => {
  try {
    let result;
    const response = await fetch(`${apiUrl}/auth`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "x-api-key": `${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    ).then(response => response.json())
      .then(data => result = data)
      .catch(error => console.error('Error:', error));

    if (response.data === undefined) {
      const decodingResult = response.decoding[0];
      throw new Error(decodingResult);
    }

    if (response.data.status.code === 500) {
      throw new Error("Internal server error. Please try again.");
    }

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export const postSignup = async (postData) => {
  try {
    const response = await fetch(`${apiUrl}/auth/signup`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "x-api-key": `${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    )

    if (response.status === 500) {
      throw new Error("Internal server error. Please try again.")
    }

    const responseBody = await response.json();
    return responseBody.data
  } catch (error) {
    console.error(error)
    throw new Error("Internal server error. Please try again.")
  }
}

export const postVerifyEmail = async (postData) => {
  try {
    const response = await fetch(`${apiUrl}/auth/verify`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "x-api-key": `${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    )

    if (response.status === 500) {
      throw new Error("Internal server error. Please try again.")
    }

    const responseBody = await response.json()
    return responseBody.data
  } catch (error) {
    console.error(error)
    throw new Error("Internal server error. Please try again.")
  }
}

export const guardTest = async (payload) => {
  try {
    let result;
    let postData = {};
    const response = await fetch(`${apiUrl}guard-test`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "x-api-key": `${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    ).then(response => response.json())
      .then(data => result = data)
      .catch(error => console.error('Error:', error));

    return response.data;
  } catch (error) {
    console.error(error)
    throw new Error("Internal server error. Please try again.");
  }
}