import React, { useState } from 'react';

import { PricingViewer } from './pricingViewer';

const appUrl = process.env.REACT_APP_URL;

export function PricingController(props) {
  const {
    errors
  } = props;

  return <PricingViewer

  />
}