import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UploadFileModal from '../modals/uploadFileModal';
import RefreshIcon from '@/components/icons/refreshIcon';
import bin from '@/assets/images/bin.svg';
import add from '@/assets/images/add.svg'
import AlertIcon from '@/components/icons/alertIcon';
import {
  FORMATTING_STATUS_COMPLETE,
  FORMATTING_STATUS_ERROR,
  FORMATTING_STATUS_IN_PROGRESS,
} from '@/constants/resume';

function Status({
  status,
  statusDisplay,
  statusError,
  createdAt,
}) {
  if (status === FORMATTING_STATUS_COMPLETE) {
    return "";
  }

  const currentDate = new Date();
  const createdDate = new Date(Date.parse(createdAt));
  const elapsedSeconds = (currentDate.getTime() - createdDate.getTime()) / 1000;

  if (
    status === FORMATTING_STATUS_ERROR ||
    (status === FORMATTING_STATUS_IN_PROGRESS && elapsedSeconds >= 300)
  ) {
    const errorMessage = statusError === null ?
      "Failed to process the resume. Delete and try again." :
      statusError;

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {errorMessage}
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <span><AlertIcon /></span>
      </OverlayTrigger>
    );
  }

  return (
    <div className="spinner-div">
      <div class="lds-dual-ring" />
    </div>
  );
}

export default function ResumeCard(props) {
  const {
    handleNextStepClick,
    handlePreviousStepClick,
    handleSelectedResumeChange,
    resumes = [],
    selectedResume,
    handleRefreshResumes,
    selectedRole,
    deleteResume,
    isLoading
  } = props;

  const [attachment, setAttachment] = useState(null);
  const [modalState, setModalState] = useState(false);

  const handleAttachmentChange = (e) => {
    const selectedAttachment = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    setAttachment(selectedAttachment);
  };

  const handleSelectFile = (selected) => {
    console.log("selectedResume", selected)
    handleSelectedResumeChange(selected);
  }

  const handleToggleModalState = () => {
    setModalState(!modalState);
  }

  return (
    <section className="resume">
      <div className="resume__inner">
        <div className="card-heading">
          <div className="text-div">
            <h4>Select A Resume <RefreshIcon onClick={handleRefreshResumes} /></h4>
          </div>
        </div>
        <ul className="resume-list">
          {isLoading === true && <li className="spinner"><div class="lds-dual-ring"></div></li>}
          {isLoading === false && resumes.map((element, index) => (
            <li onClick={() => handleSelectFile(element)} key={index} className={selectedResume === element ? 'selected' : ''}>
              <div class="options"><button onClick={() => deleteResume(element)}><img className="bin" alt="Delete" src={bin} /></button></div>
              <div class="resume-name">{element.filename}</div>
              <Status
                status={element.formatting_status}
                statusDisplay={element.formatting_status_display}
                statusError={element.formatting_status_error}
                createdAt={element.created_at}
              />
            </li>
          ))}
        </ul>
        <div className="add-button-div">
          <button className="add-resume" onClick={handleToggleModalState}><img src={add} alt="Add a New Role" /></button>
        </div>

        {modalState && <UploadFileModal
          handleToggleModalState={handleToggleModalState}
          parentModalState={modalState}
          handleSelectFile={handleSelectFile}
          type="resume"
          handleRefresh={handleRefreshResumes}
          selectedRole={selectedRole}
          isLoading={isLoading}
        >
        </UploadFileModal>}
      </div>
    </section>
  )
}
