import React, { useState } from 'react';
import close from '../../../../assets/images/bluecross.svg';

export default function ErrorModal(props) {
  const {
    toggleModalState,
    errors,
    showErrors,
  } = props;

  function handleModalStateChange() {
    let newValue = !errors;
    toggleModalState(newValue);
  }

  return (
    <section className='error-modal'>
      <div className='background' />
      <div className='error-modal__inner'>
        <img src={close} onClick={handleModalStateChange} alt="Close" />
        <div className="form-container">
          <h4><b>{errors.message}</b></h4>
          {(showErrors) && <p>{Object.values(errors.error)[0] ?? errors.statusText}</p>}
          <button onClick={handleModalStateChange}>
            Close
          </button>
        </div>
      </div>
    </section >
  )
}
