import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

export default function ConfirmCard(props) {
  const {
    selectedTemplate,
    selectedResume,
    handlePreviousStepClick,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  const handleDownloadFile = async () => {
    setIsLoading(true);
    const response = await fetch(`${apiUrl}/formatter/download`, {
      method: "POST",
      headers: {
        "x-api-key": `${apiKey}`,
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify({
        "resumeID": selectedResume.id,
        "templateID": selectedTemplate.id,
      })
    });

    setIsLoading(false);

    if (response.ok) {
      const data = await response.json();
      setDownloadUrl(data.data.data.url);

      window.open(data.data.data.url, '_blank', 'noopener');
    } else {
      console.error(`File download failed with status: ${response.status}`);
    }
  }


  const copyToClipboard = () => {
    if (downloadUrl != null) {
      navigator.clipboard.writeText(downloadUrl)
        .then(() => {
          alert('Text copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  return (
    <section style={{ height: '339px', width: '100%' }}>
      <div className="confirm" style={{ maxHeight: '340px', height: '100%' }}>
        <Row fluid="true" className="justify-content-center" style={{ height: '80px' }}>
          <Col className="px-0" style={{ height: '80px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            {isLoading === true && <div className="spinner-div"><div class="lds-dual-ring"></div></div>}
          </Col>
        </Row>
        <Col fluid="true" className="justify-content-center">
          <Col className="px-0" style={{ justifyContent: 'space-evenly', display: 'flex', flexDirection: 'column' }}>
            <Col fluid="true" className="justify-content-center" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
              <button className="button" download="Resume" target="_blank"
                onClick={handleDownloadFile}>Download Resume</button>
            </Col>
            <Col fluid="true" className="justify-content-center" >
              {downloadUrl != null &&
                <div className="link-container">
                  <p className="link-text">Can't see your file? Copy the link below and paste into your browser</p>
                  <button className="button" onClick={copyToClipboard}>Copy Download Link</button>
                </div>}
            </Col>
          </Col>
        </Col>
      </div>
    </section >
  )
}
