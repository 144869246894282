import React from "react";

import { DashboardViewer } from "./dashboardViewer";

export function DashboardController({

}) {
  return (
    <DashboardViewer

    />
  )
}