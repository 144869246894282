import React from 'react';

import Container from 'react-bootstrap/Container';

import LoginForm from './components/loginForm';

export function LoginViewer({
  email,
  handleEmailChange,
  password,
  handlePasswordChange,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  isSubmitting,
  handleSubmitForm,
  errors,
  signup,
  toggleSignup,
  login,
  guardCheck,
  isSignup,
  authenticating
}) {
  return (
    <Container
      className='vh-100'
      fluid="true"
    >
      <LoginForm
        email={email}
        guardCheck={guardCheck}
        handleEmailChange={handleEmailChange}
        password={password}
        handlePasswordChange={handlePasswordChange}
        firstName={firstName}
        handleFirstNameChange={handleFirstNameChange}
        lastName={lastName}
        handleLastNameChange={handleLastNameChange}
        isSubmitting={isSubmitting}
        handleSubmitForm={handleSubmitForm}
        errors={errors}
        toggleSignup={toggleSignup}
        signup={signup}
        login={login}
        isSignup={isSignup}
        authenticating={authenticating}
      />
    </Container>
  )
}