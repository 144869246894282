import React, { useState } from 'react';
import Diamond from '@/assets/images/diamond.svg'


export default function Pricing(props) {
  const {

  } = props

  return (
    <section className="pricing">
      <div className="pricing__container">
        <h2>Pricing</h2>
        <div className="pricing__inner">
          <div className="selector"></div>
          <div className="prices-company">
            <div className="pricing-plan">
              <div className="plan-icon">
                <img src={Diamond} alt="Pricing Plan" />
              </div>
              <h3 className="price-heading">Individual</h3>
              <div className="pricing-plan-content">
                <ul>
                  <li>40 format credits per month</li>
                  <li>1 Account</li>
                  <li>Unlimited downloads</li>
                  <li>Download as .docx</li>
                  <li></li>
                </ul>
                {/* <h2 className="price">$40<span className="period-div">/mo</span></h2> */}
                <h2 className="price">$??<span className="period-div">/mo</span></h2>
                <button className="cta">Get Started</button>
              </div>
            </div>
            <div className="pricing-plan">
              <div className="plan-icon">
                <img src={Diamond} alt="Pricing Plan" />
              </div>
              <h3 className="price-heading">Silver</h3>
              <div className="pricing-plan-content">
                <ul>
                  <li>90 format credits per month</li>
                  <li>Up to 4 accounts</li>
                  <li>Unlimited downloads</li>
                  <li>Download as .docx</li>
                </ul>
                {/* <h2 className="price">$75<span className="period-div">/mo</span></h2> */}
                <h2 className="price">$??<span className="period-div">/mo</span></h2>
                <button className="cta">Get Started</button>
              </div>
            </div>
            <div className="pricing-plan">
              <div className="plan-icon">
                <img src={Diamond} alt="Pricing Plan" />
              </div>
              <h3 className="price-heading">Gold</h3>
              <div className="pricing-plan-content">
                <ul>
                  <li>200 format credits per month</li>
                  <li>Up to 10 accounts</li>
                  <li>Unlimited downloads</li>
                  <li>Download as .docx</li>
                </ul>
                {/* <h2 className="price">$150<span className="period-div">/mo</span></h2> */}
                <h2 className="price">$??<span className="period-div">/mo</span></h2>
                <button className="cta">Get Started</button>
              </div>
            </div>
            <div className="pricing-plan">
              <div className="plan-icon">
                <img src={Diamond} alt="Pricing Plan" />
              </div>
              <h3 className="price-heading">Diamond</h3>
              <div className="pricing-plan-content">
                <ul>
                  <li>600 format credits per month</li>
                  <li>Up to 20 accounts</li>
                  <li>Unlimited downloads</li>
                  <li>Download as .docx</li>
                </ul>
                {/* <h2 className="price">$300<span className="period-div">/mo</span></h2> */}
                <h2 className="price">$??<span className="period-div">/mo</span></h2>
                <button className="cta">Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
