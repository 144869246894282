import React, { useState } from 'react';
import close from '../../../../assets/images/bluecross.svg';

export default function AddRoleModal(props) {
  const {
    handleToggleModalState,
    parentModalState,
    handleAddRole,
    selectedRole,
    type,
    handleRefresh,
    handleToggleTemplateStylingModal,
    handleSetSelected
  } = props;

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  const [attachment, setAttachment] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleModalStateChange = () => {
    handleToggleModalState(!parentModalState);
  }

  const handleToggleTemplateStylingModalChange = () => {
    handleToggleTemplateStylingModal();
  }

  const handleAttachmentChange = (e) => {
    const selectedAttachment = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    setAttachment(selectedAttachment);
  };

  const handleUpload = async () => {
    if (attachment) {
      console.log("Uploading attachment...");

      const formData = new FormData();
      formData.append("file", attachment);
      formData.append("job_id", selectedRole.id);
      formData.append("file_name", attachment.name);

      try {
        setIsLoading(true);
        const result = await fetch(`${apiUrl}/formatter/${type}/upload`, {
          method: "POST",
          body: formData,
          headers: {
            "x-api-key": `${apiKey}`,
          },
          mode: 'cors',
        })
        const data = await result.json();

        if (type == 'template') {
          handleSetSelected(data.data.data);
        }
        setIsLoading(false);
        await handleRefresh();
        handleModalStateChange();

        if (type == 'template') {
          handleToggleTemplateStylingModalChange();
        }
        return data;
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <section className='upload-file-modal'>
      <div className='background' />
      <div className='upload-file-modal__inner'>
        <h2>Upload a {type}</h2>
        <img src={close} onClick={handleModalStateChange} alt="Close" />
        {isLoading === true && <div className="spinner"><div class="lds-dual-ring"></div></div>}
        {isLoading === false && <div className="upload-file-body">
          <input id="attachment" type="file" onChange={handleAttachmentChange} />
        </div>}
        <button className={`button ${(!attachment) ? "disabled" : ""}`} disable={!attachment} onClick={handleUpload}>
          Upload Attachment
        </button>
      </div>
    </section >
  )
}