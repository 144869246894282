import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FORMATTING_STATUS_IN_PROGRESS } from '@/constants/resume';

import {
  getTemplates as getTemplatesApi,
  getResumes as getResumesApi,
  getRoles as getRolesApi,
  deleteRole as deleteRoleApi,
  addRole as addRoleApi,
  deleteTemplate as deleteTemplateApi,
  deleteResume as deleteResumeApi,
} from '@/apis/Formatter/formatterApi';

import FormatViewer from './formatViewer';

export default function FormatController(props) {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [resumesMap, setResumesMap] = useState({});
  const [selectedResume, setSelectedResume] = useState(null);
  const [step, setStep] = useState(0);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [toggleGetRoles, setToggleGetRoles] = useState(0);
  const [roleTitleShowError, setRoleTitleShowError] = useState(null);
  const [roleIndustryShowError, setRoleIndustryShowError] = useState(null);
  const [roleLocationShowError, setRoleLocationShowError] = useState(null);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [resumesLoading, setResumesLoading] = useState(false);
  const [resumesPollingID, setResumesPollingID] = useState(null);
  const stepCount = 4;

  // polling interval in milliseconds
  const pollingInterval = 15000;

  const resumes = useMemo(() => {
    return Object.values(resumesMap);
  }, [resumesMap]);

  const resumesInProgress = useMemo(() => {
    return resumes.filter(resume => {
      const currentDate = new Date();
      const createdDate = new Date(Date.parse(resume.created_at));
      const elapsedSeconds = (currentDate.getTime() - createdDate.getTime()) / 1000;

      // only interested where formatting status is in progress and elapsed seconds
      // is less than 180 (3 minutes) to cater for long running error
      return resume.formatting_status === FORMATTING_STATUS_IN_PROGRESS &&
        elapsedSeconds <= 180;
    });
  }, [resumes]);

  const getRoles = useCallback(
    async () => {
      try {
        setRolesLoading(true);
        setRoles([]);
        const roles = await getRolesApi();
        setRolesLoading(false);
        setRoles(roles);
      } catch (error) {
        console.error(error);
      }
    }, [setRoles, setRolesLoading, getRolesApi]);

  const getTemplates = useCallback(
    async () => {
      if (selectedRole === undefined || selectedRole === null) {
        return;
      }
      try {
        setTemplatesLoading(true);
        const templates = await getTemplatesApi(selectedRole);
        const templatesArray = [];
        for (const key in templates) {
          if (templates.hasOwnProperty(key)) {
            templatesArray.push(templates[key]);
          }
        }
        setTemplates(templatesArray);
        setTemplatesLoading(false);
      } catch (error) {
        console.error(error);
      }
    }, [setTemplates, selectedRole])

  const getResumes = useCallback(
    async () => {
      if (selectedRole === undefined || selectedRole === null) {
        return;
      }
      try {
        setResumesLoading(true);
        const resumes = await getResumesApi(selectedRole);
        const resumesMap = resumes.reduce((resumesMap, resume) => {
          resumesMap[resume.id] = resume;
          return resumesMap;
        }, {});

        setResumesMap(resumesMap);
        setResumesLoading(false);
      } catch (error) {
        console.error(error);
      }
    }, [selectedRole, setResumesMap])

  useEffect(() => {
    if (selectedRole !== undefined) {
      getTemplates();
      getResumes();
    }
  }, [selectedRole, getTemplates, getResumes])

  const pollResume = useCallback(async () => {
    const resumeIDs = resumesInProgress.map(resume => resume.id);
    if (resumeIDs.length === 0) {
      setResumesPollingID(null);
      return;
    }

    try {
      const newResumesMap = JSON.parse(JSON.stringify(resumesMap));
      const resumes = await getResumesApi(selectedRole, resumeIDs);
      resumes.forEach(resume => {
        newResumesMap[resume.id] = resume;
      });
      setResumesMap(newResumesMap);
    } catch (e) {
      console.error(e);
    }

    const id = setTimeout(pollResume, pollingInterval);
    setResumesPollingID(id);
  }, [resumesInProgress, setResumesPollingID, setResumesMap]);

  useEffect(() => {
    clearTimeout(resumesPollingID);

    const id = setTimeout(pollResume, pollingInterval);
    setResumesPollingID(id);
  }, [resumesInProgress]);

  useEffect(() => {
    return () => {
      if (resumesPollingID !== null) {
        clearTimeout(resumesPollingID);
      };
    };
  });

  const handleNextStepClick = useCallback(
    () => {
      if (step >= stepCount) {
        return;
      }
      setStep(step + 1);
    }, [step, setStep]);

  const handlePreviousStepClick = useCallback(
    () => {
      if (step <= 0) {
        return;
      }
      setStep(step - 1);
    }, [step, setStep]);

  const handleSelectedTemplateChange = useCallback(
    (val) => {
      setSelectedTemplate(val);
    }, [setSelectedTemplate]);

  const handleSelectedResumeChange = useCallback(
    (val) => {
      setSelectedResume(val);
    }, [setSelectedResume]);

  const handleSelectedRoleChange = useCallback(
    async (val) => {
      setTemplates([]);
      setResumesMap({});
      setSelectedRole(val);
    }, [getTemplates, setTemplates, setResumesMap])

  const handleRefreshTemplates = useCallback(() => {
    getTemplates();
  }, [getTemplates]);

  const handleRefreshResumes = useCallback(() => {
    getResumes();
  }, [getResumes]);

  const handleAddRole = useCallback(
    async (formData) => {
      try {
        const response = await addRoleApi(formData);
        setSelectedRole(response.data.data.id);
        getRoles();
      } catch (error) {
        console.error(error);
      }
    }, [getRoles, setSelectedRole])

  useEffect(() => {
    if (toggleGetRoles === 0) {
      return;
    }
    getRoles();
  }, [getRoles, toggleGetRoles]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleRefreshRoles = useCallback(() => {
    setToggleGetRoles(toggleGetRoles + 1);
  }, [toggleGetRoles, setToggleGetRoles]);

  const deleteRole = useCallback(
    async (object_id) => {
      try {
        setRolesLoading(true);
        await deleteRoleApi(object_id);
        setRolesLoading(true);
        getRoles();
      } catch (error) {
        console.error(error);
      }
    }, [getRoles, setRolesLoading])

  const deleteTemplate = useCallback(
    async (object_id) => {
      try {
        setTemplatesLoading(true);
        await deleteTemplateApi(object_id);
        setTemplatesLoading(false);
        getTemplates();
      } catch (error) {
        console.error(error);
      }
    }, [getTemplates])

  const deleteResume = useCallback(
    async (object_id) => {
      try {
        setResumesLoading(true);
        await deleteResumeApi(object_id);
        setResumesLoading(false);
        getResumes();
      } catch (error) {
        console.error(error);
      }
    }, [getResumes])

  function validateRoleTitle(value) {
    if (value.trim() !== "") {
      setRoleTitleShowError(false);
      return false;
    }

    setRoleTitleShowError(true);
    return true;
  }

  function validateRoleIndustry(value) {
    if (value.trim() !== "") {
      setRoleIndustryShowError(false);
      return false;
    }

    setRoleIndustryShowError(true);
    return true;
  }

  function validateRoleLocation(value) {
    if (value.trim() !== "") {
      setRoleLocationShowError(false);
      return false;
    }

    setRoleLocationShowError(true);
    return true;
  }

  const resetFormValues = () => {
    setRoleTitleShowError(null);
    setRoleIndustryShowError(null);
    setRoleLocationShowError(null);
  }

  function validateForm(formValues) {
    const titleInvalid = validateRoleTitle(formValues.role_title);
    const industryInvalid = validateRoleIndustry(formValues.role_industry);
    const locationInvalid = validateRoleLocation(formValues.role_location);

    const validationValues = {
      'role_title': titleInvalid,
      'role_industry': industryInvalid,
      'role_location': locationInvalid,
    }

    return validationValues;
  }


  return <FormatViewer
    roles={roles}
    templates={templates}
    resumes={resumes}
    selectedRole={selectedRole}
    selectedTemplate={selectedTemplate}
    selectedResume={selectedResume}
    handleRefreshRoles={handleRefreshRoles}
    handleRefreshTemplates={handleRefreshTemplates}
    handleRefreshResumes={handleRefreshResumes}
    handleSelectedRoleChange={handleSelectedRoleChange}
    handleSelectedTemplateChange={handleSelectedTemplateChange}
    handleSelectedResumeChange={handleSelectedResumeChange}
    deleteRole={deleteRole}
    deleteTemplate={deleteTemplate}
    deleteResume={deleteResume}
    handleAddRole={handleAddRole}
    step={step}
    handleNextStepClick={handleNextStepClick}
    handlePreviousStepClick={handlePreviousStepClick}
    roleTitleShowError={roleTitleShowError}
    roleIndustryShowError={roleIndustryShowError}
    roleLocationShowError={roleLocationShowError}
    resetFormValues={resetFormValues}
    validateForm={validateForm}
    rolesLoading={rolesLoading}
    resumesLoading={resumesLoading}
    templatesLoading={templatesLoading}
  />
}
