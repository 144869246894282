import React, { useState } from 'react';

import Button from "@/components/buttons/button";
import FileUploadModal from "@/components/fileUploadModal";

export default function Test() {
  const [isOpen, setIsOpen] = useState(false);

  const handleSetIsOpen = (val) => {
    setIsOpen(val);
  }

  const handleUploadChange = (file) => {
    console.log(file);
  }

  return (
    <>
      <div style={{ paddingTop: "100px" }}>
        <Button onClick={() => handleSetIsOpen(true)}>
          Upload File
        </Button>
      </div>

      <FileUploadModal
        isOpen={isOpen}
        setIsOpen={handleSetIsOpen}
        handleUploadChange={handleUploadChange}
      />
    </>
  );
}
