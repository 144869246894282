import React, { useState, useEffect } from "react"
import facebook from '../assets/images/facebook.svg';
import instagram from '../assets/images/instagram.svg';
import Fade from 'react-reveal/Fade'
import Logo from '../assets/images/logo.js';


export default function Header(props) {
  const [menuActive, setMenuActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);
  }, []);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  }

  // Hide or show the menu.
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 150) {
      document.querySelector('.header').classList.add('header--scrolled')
    } if (currentScrollPos < 150) {
      document.querySelector('.header').classList.remove('header--scrolled')
    }
    if (currentScrollPos >= (window.innerHeight - 20)) {
      setColor('#000000')
      let header = document.querySelector('.header__inner nav');
      header.classList.add('header--color');
      let offcanvas = document.querySelector('.off-canvas__nav');
      offcanvas.classList.add('off-canvas__nav--color');
    }
    if (currentScrollPos < (window.innerHeight - 20)) {
      setColor('#ffffff')
      let header = document.querySelector('.header__inner nav');
      header.classList.remove('header--color');
      let offcanvas = document.querySelector('.off-canvas__nav');
      offcanvas.classList.remove('off-canvas__nav--color');
    }
  }

  const _toggleOffCanvas = () => {
    setMenuActive(!menuActive)
    document.querySelector('body').classList.toggle('menu-open')
  }

  return (
    <>
      <section className="header">
        <div className="header__inner">
          <a href="/"><Logo color={color} /></a>
          <nav>
            <a className="nav-button" href="/format">Format a Resume</a>
            <a className="nav-button" href="/">About Us</a>
            <a className="nav-button" href="/pricing">Pricing</a>
            <a className="nav-button" href="/login">Login</a>

            <div className="socials">
              <a href="/"><img src={facebook} alt="Facebook" /></a>
              <a href="/"><img src={instagram} alt="Instagram" /></a>
            </div>
          </nav>
          <button className={menuActive ? 'hamburger active' : 'hamburger'} onClick={_toggleOffCanvas}>
            <span className='lines'></span>
          </button>
        </div>
      </section>

      <div className={`off-canvas ${menuActive ? 'active' : ''}`}>
        <nav className='off-canvas__nav'>
          <Fade left when={menuActive === true}><a href="/format" className='nav-button'>Format a Resume</a></Fade>
          <Fade left when={menuActive === true}><a href="/" className='nav-button'>About Us</a></Fade>
          <Fade left when={menuActive === true}><a href="/pricing" className='nav-button'>Pricing</a></Fade>
          <Fade left when={menuActive === true}><a href="/login" className='nav-button'>Login</a></Fade>
        </nav>
      </div>
    </>
  );
}
