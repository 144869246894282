import React from "react";

import { MdRefresh } from "react-icons/md";

import Icon from "@/components/icons/icon";

export default function RefreshIconViewer(props) {
  return (
    <Icon {...props}>
      <MdRefresh />
    </Icon>
  )
}