import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import bin from '@/assets/images/bin.svg';
import AddRoleModal from '../modals/addRoleModal';
import add from '@/assets/images/add.svg'

import RefreshIcon from '@/components/icons/refreshIcon';

export default function ResumeCard(props) {
  const {
    handleSelectedRoleChange,
    roles = [],
    selectedRole,
    handleAddRole,
    handleRefreshRoles,
    deleteRole,
    resetFormValues,
    roleTitleShowError,
    roleIndustryShowError,
    roleLocationShowError,
    validateForm,
    isLoading
  } = props;

  const [modalState, setModalState] = useState(false);

  const handleToggleModalState = useCallback(
    () => {
      setModalState(!modalState);
    }, [setModalState, modalState]);

  return (
    <section className="role">
      <div className="role__inner">
        <div className="card-heading">
          <div className="text-div">
            <h4>Select A Role <RefreshIcon onClick={handleRefreshRoles} /></h4>
          </div>
        </div>
        <ul className="roles-list">
          {isLoading === true && <li className="spinner-div"><div class="lds-dual-ring"></div></li>}
          {isLoading === false && roles.map((element, index) => (
            <li onClick={() => handleSelectedRoleChange(element)} key={index} className={selectedRole === element ? 'selected' : ''}>
              <div class="options"><button onClick={() => deleteRole(element)}><img className="bin" alt="Delete" src={bin} /></button></div>
              <div class="role-name">{element.role_title}</div>
            </li>
          ))}
        </ul>
        <div className="add-button-div">
          <button className="add-role" onClick={handleToggleModalState}><img src={add} alt="Add a New Role" /></button>
        </div>

        {modalState && <AddRoleModal
          handleToggleModalState={handleToggleModalState}
          parentModalState={modalState}
          handleAddRole={handleAddRole}
          resetFormValues={resetFormValues}
          roleTitleShowError={roleTitleShowError}
          roleIndustryShowError={roleIndustryShowError}
          roleLocationShowError={roleLocationShowError}
          validateForm={validateForm}
        >
        </AddRoleModal>}
      </div>
    </section >
  )
}
