import React, { useState } from 'react'

import { Outlet } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import '@/assets/scss/main.scss'
import ErrorModal from '../../../src/routes/app/format/modals/errorModal';
import Header from '@/components/header'
import Footer from '@/components/footer'
import { Col, Row } from 'react-bootstrap';

import Modal from 'react-modal';
Modal.setAppElement(`#root`);

const Layout = (props) => {
  const {
    errors,
    showErrors,
    toggleModalState
  } = props;
  const uris = window.location.pathname.split("/").filter(uri => uri !== "") || [];

  const [modalState, setModalState] = useState(false);

  if (uris[0] === "app") {
    return (
      <AppLayout>
        <Outlet />
      </AppLayout>
    )
  }

  return (
    <DefaultLayout>
      <Outlet />
      {(errors && showErrors) && < ErrorModal
        toggleModalState={toggleModalState}
        errors={errors}
        showErrors={showErrors}
      >
      </ErrorModal>}
    </DefaultLayout >
  )
}

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

const AppLayout = ({ children }) => {
  return (
    <>
      <Row>
        <Col style={{ position: 'absolute' }}>
          <Row style={{ height: '100px' }}>
            <Col>Test</Col>
          </Row>
          <Row style={{ height: '100px', position: 'sticky', top: '0px' }}>
            <Col xs="12">
              <Row className="align-items-center">
                <Col xs="6">
                  <Row>
                    <Col xs="12">
                      Search
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">

                    </Col>
                  </Row>
                </Col>
                <Col xs="6">
                  Filters
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ minHeight: '100vh' }}>
            <Col xs="12">
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Layout

