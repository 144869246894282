import React, { useState } from 'react';
import close from '../../../../assets/images/bluecross.svg';
import warning from '../../../../assets/images/warning.svg';

export default function AddRoleModal(props) {
  const {
    handleToggleModalState,
    parentModalState,
    handleAddRole,
    resetFormValues,
    roleTitleShowError,
    roleIndustryShowError,
    roleLocationShowError,
    validateForm
  } = props;

  const handleModalStateChange = () => {
    handleToggleModalState(!parentModalState);
    resetFormValues();
  }

  const handleFormSubmit = async (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();

    const formData = new FormData(event.target);

    // Convert Form Data to an object
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    const validation = validateForm(formDataObject);

    if (validation.role_title === false && validation.role_industry === false && validation.role_location === false) {
      await handleAddRole(formDataObject);
      handleModalStateChange();
    }
  };

  return (
    <section className='add-role-modal'>
      <div className='background' />
      <div className='add-role-modal__inner'>
        <img src={close} onClick={handleModalStateChange} alt="Close" />
        <div className="form-container">
          <h2>Add a Role</h2>
          <form onSubmit={handleFormSubmit}>
            {roleTitleShowError === true && <div className="alert-box"><img src={warning} alt="Warning"></img></div>}
            <input type="role_title" name="role_title" id="role_title" placeholder="Role Title" className="role-input" />
            {roleIndustryShowError === true && <div className="alert-box"><img src={warning} alt="Warning"></img></div>}
            <input type="role_industry" name="role_industry" id="role_industry" placeholder="Role Industry" className="role-input" />
            {roleLocationShowError === true && <div className="alert-box"><img src={warning} alt="Warning"></img></div>}
            <input type="role_location" name="role_location" id="role_location" placeholder="Role Location" className="role-input" />
            <input type="company_name" name="company_name" id="company_name" placeholder="Company Name (Optional)" className="login-input" />
            <input type="company_contact_name" name="company_contact_name" id="company_contact_name" placeholder="Company Contact Name (Optional)" className="login-input" />
            <input type="company_contact_email" name="company_contact_email" id="company_contact_email" placeholder="Company Contact (Optional)" className="login-input" />
            <input type="company_contact_phone" name="company_contact_phone" id="company_contact_phone" placeholder="Company Phone (Optional)" className="login-input" />
            <input type="visa_status" name="visa_status" id="visa_status" placeholder="Visa Status (Optional)" className="role-input" />
            <input type="security_clearance" name="security_clearance" id="security_clearance" placeholder="Security Clearance (Optional)" className="role-input" />
            <div className="form-control">
              <button type="submit">Add a Role</button>
            </div>
          </form>
        </div>
      </div>
    </section >
  )
}
