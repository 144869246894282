import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { handleSetAccessToken, handleSetRefreshToken } from "./routes/app/auth/loginController";
import checkAuthorization from "@/lib/check-auth";
import Layout from "@/components/layouts";
import Home from "@/routes/app/banner/banner";
import Login from "@/routes/app/auth";
import Dashboard from "@/routes/app/dashboard";
import Format from "@/routes/app/format";
import Verify from "@/routes/app/verify";
import Pricing from "@/routes/app/pricing";
import Test from "@/routes/test";

const App = () => {
  const [isConditionMet, setIsConditionMet] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');

  useEffect(() => {
    // Your condition check logic
    const conditionCheck = () => {
      if (accessToken && refreshToken) {
        setIsConditionMet(true);
      } else {
        setIsConditionMet(false);
      }
    };

    conditionCheck();
  }, [accessToken, refreshToken]);

  useEffect(() => {
    console.log('Updated errors:', errors);
  }, [errors]);

  function toggleModalState(newState) {
    setShowErrors(newState);
  }

  const resetErrorState = () => {
    setErrors({});
  };

  useEffect(() => {
    const originalFetch = window.fetch;

    window.fetch = async function (input, init) {
      if (!init) {
        init = {};
      }
      if (!init.headers) {
        init.headers = new Headers();
      }

      if (init.headers instanceof Headers) {
        init.headers.append('Access-Token', accessToken);
        init.headers.append('Refresh-Token', refreshToken);
      } else if (init.headers instanceof Array) {
        init.headers.push([['Access-Token', accessToken], ['Refresh-Token', refreshToken]]);
      } else {
        init.headers['Access-Token'] = accessToken;
        init.headers['Refresh-Token'] = refreshToken;
      }

      const response = await originalFetch(input, init);

      // response is returning {error: 'Expired token'}. I've checked this endpoint: cms.formatmate.com/api/formatter/resume/list and it returns normally
      // frontend is having issue after 15 mins. Seems like when refresh-token isnt valid it doesn't auto refresh
      // should we be adding logic to handle this scenario here? Is there a better way to tackle this?
      // is this not the right approach?
      // going to this endpoint on prod: prd.cms.formatmate.com/api/formatter/role/list

      const clonedResponse = response.clone(); // Clone the response to read the body separately

      const data = await clonedResponse.json();

      if (data.data.status.code > 300 && data.data.status.message) {
        setErrors(data.data.status);
        setShowErrors(!showErrors);
      }

      if (response.status >= 200 && response.status < 300) {
        const newAccessToken = response.headers.get('Access-Token');
        const newRefreshToken = response.headers.get('Refresh-Token');

        if ((newAccessToken !== null && newRefreshToken !== null) && (newAccessToken !== 'null' && newRefreshToken !== 'null')) {
          handleSetAccessToken(newAccessToken);
          handleSetRefreshToken(newRefreshToken);
        }
      }

      return response;
    };

    return () => {
      window.fetch = originalFetch;
    };
  }, [accessToken, refreshToken]);

  return (
    <Router>
      <Routes>
        <Route element={<Layout errors={errors} showErrors={showErrors} toggleModalState={toggleModalState} />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login errors={errors} />} />
          <Route exact path="/dashboard" element={checkAuthorization() ? <Dashboard /> : <Navigate replace to="/login" />} />
          <Route exact path="/format" element={isConditionMet ? <Format errors={errors} /> : <Login />} />
          <Route exact path="/verify" element={<Verify errors={errors} />} />
          <Route exact path="/test" element={isConditionMet ? <Test /> : <Navigate to="/login" replace />} />
          <Route exact path="/pricing" element={<Pricing errors={errors} />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;