import React, { useState } from 'react';
import Pricing from '../../../components/pricing'

export function PricingViewer(props) {
  const {
  } = props;

  return (
    <section className='pricing'>
      <Pricing />
    </section >
  );
}