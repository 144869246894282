import React, { useState } from 'react';

import Button from '@/components/buttons/button';
import Modal from '@/components/modals/modal';
import ModalHeader from '@/components/modals/modalHeader';
import ModalBody from '@/components/modals/modalBody';
import ModalFooter from '@/components/modals/modalFooter';

import styles from "./styles.module.scss";

export default function FileUploadModalViewer(props) {
  const {
    isOpen,
    setIsOpen,
    handleUploadChange,
    headerComponent,
    supportedFilesText = [],
  } = props;

  const [dragActive, setDragActive] = useState(false);

  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleUploadChange(e.target.files[0]);
      setIsOpen(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    };
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUploadChange(e.dataTransfer.files[0]);
      setIsOpen(false);
    };
  };

  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)}>
      <ModalHeader closeButton>
        {!headerComponent &&
          <h2>Upload File</h2>
        }
        {headerComponent &&
          <div>{headerComponent}</div>
        }
      </ModalHeader>

      <ModalBody style={{ height: "300px" }}>
        <form style={{ background: "none", width: "100%", }} onSubmit={(e) => e.preventDefault()}>
          <label
            htmlFor="getFile"
            className={styles.fileLabel}
          >
            <div
              className={dragActive ? styles.uploadBoxActive : styles.uploadBox}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <div className={styles.drag}>
                Drop your file here or{" "}
                <div className={styles.browse}>
                  Browse
                  <input
                    type="file"
                    id="getFile"
                    className={styles.fileIcon}
                    onChange={handleFileInputChange}
                  />
                </div>
              </div>
            </div>
          </label>

          {supportedFilesText.length > 0 &&
            <p className={styles.info}>Support files: {supportedFilesText.join(',')}</p>
          }
        </form>
      </ModalBody>

      <ModalFooter>
        <Button onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}