import React, { useState } from 'react';
import close from '../../../../assets/images/bluecross.svg';

export default function TemplateStylingModal(props) {
  const {
    handleToggleTemplateStylingModal,
    template
  } = props;

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  // Initial styling values
  const initialStyling = {
    margin: '1',
    font_family: 'Arial',
    paragraph_font_size: '10',
    heading_font_size: '16',
    template_id: template.id,
    has_cover_page: 'false',
  };

  const [modalState, setModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleStylingModalStateChange = () => {
    handleToggleTemplateStylingModal();
  }

  const [formData, setFormData] = useState({
    margin: '',
    font_family: '',
    paragraph_font_size: '',
    heading_font_size: '',
    has_cover_page: ''
  });

  const [styling, setStyling] = useState(initialStyling);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStylingChange = (e) => {
    const { name, value } = e.target;

    // if has_cover_page checkbox checked return true
    if (e.target.checked) {
      setStyling({
        ...styling,
        [name]: 'true',
      });
    } else {
      setStyling({
        ...styling,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form data
    // Call the handleSubmit function passed down as a prop
    handleSaveStyling(styling);
  };

  const handleSaveStyling = async (formData) => {
    if (formData) {
      const payload = {};
      payload.data = formData;
      try {
        setIsLoading(true);
        const result = await fetch(`${apiUrl}/formatter/template/styling`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "x-api-key": `${apiKey}`,
            "Content-Type": "application/json",
          },
          mode: 'cors',
        })
        const data = result.json();
        handleToggleTemplateStylingModal();
        return data;
      } catch (error) {
        console.log('error', error);
        return error;
      }
    }
  };

  return (
    <section className='template-styling-modal'>
      <div className='background' />
      <div className='template-styling-modal__inner'>
        <img src={close} onClick={handleStylingModalStateChange} alt="Close" />
        <form className="sign-up" onSubmit={handleSubmit} style={{ ...styling }}>
          <h2>Create Account</h2>
          <div className="form-control">
            <label for="font_family">Choose a font:</label>
            <select name="font_family" id="font_family" value={styling.font_family} onChange={handleStylingChange}>
              <option value="Arial">Arial</option>
              <option value="Calibri">Calibri</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Sans-serif">Sans Serif</option>
            </select>
            <label for="heading_font_size">Choose a heading font size (pt):</label>
            <input type="number" name="heading_font_size" id="heading_font_size" value={styling.heading_font_size} onChange={handleStylingChange} min="10" max="34" />
            <label for="paragraph_font_size">Choose a paragraph font size (pt):</label>
            <input type="number" name="paragraph_font_size" id="paragraph_font_size" value={styling.paragraph_font_size} onChange={handleStylingChange} min="8" max="10" />
            <label for="margin">Choose a margin height (cm):</label>
            <input type="number" name="margin" id="margin" value={styling.margin} onChange={handleStylingChange} min="0" max="8" />
            <label>Has cover page?</label>
            <input type="checkbox" name="has_cover_page" id="has_cover_page" value={styling.has_cover_page} onChange={handleStylingChange}></input>
          </div>
          <div className="form-control">
            <button type="submit">
              Submit
            </button>
          </div>
        </form >
      </div >
    </section >
  )
}