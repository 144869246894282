import React from "react";

import job from '@/assets/images/job.svg';
import file from '@/assets/images/file.svg';
import confirm from '@/assets/images/download.svg';
import TemplateCard from './cards/template';
import ResumeCard from './cards/resume';
import RoleCard from './cards/role';
import ConfirmCard from './cards/confirm';
import { Col, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade'
import { FORMATTING_STATUS_COMPLETE } from "@/constants/resume";

export default function FormatViewer(props) {
  const {
    templates,
    handleRefreshTemplates,
    selectedTemplate,
    handleSelectedTemplateChange,
    resumes,
    handleRefreshResumes,
    selectedResume,
    handleSelectedResumeChange,
    step,
    handleNextStepClick,
    handlePreviousStepClick,
    selectedRole,
    handleAddRole,
    handleSelectedRoleChange,
    roles,
    handleRefreshRoles,
    deleteRole,
    deleteTemplate,
    deleteResume,
    resetFormValues,
    roleTitleShowError,
    roleIndustryShowError,
    roleLocationShowError,
    validateForm,
    rolesLoading,
    templatesLoading,
    resumesLoading,
    setDeletedResumesList
  } = props;

  return (
    <Fade distance="40px" bottom>
      <section className='formatter'>
        <div className='formatter__inner'>
          <div className="formatter__heading">
            <div className="steps">
              <div className="progress-line" />
              <div className={`${((step === 0) ? 'progress-line-role-step' : '') || ((step === 1) ? 'progress-line-template-step' : '') || ((step === 2) ? 'progress-line-resume-step' : '') || ((step === 3) ? 'progress-line-download-step' : '')}`}></div>
              {/* <div className={`line ${step >= 0 ? "active" : selectedRole != null ? "selected" : ""}`}><div className={`animation-line ${step >= 0 ? "active" : ""}`} /></div> */}
              <div className={`step ${step === 0 ? "selected" : ""}`}>
                <div className={`img-div ${step >= 0 ? "active" : ""}`}>
                  <img src={job} alt='template' />
                </div>
                <div className="text-div">
                  <h5>Role</h5>
                </div>
              </div>
              {/* <div className={`line ${step >= 1 ? "active" : (selectedTemplate != null && selectedRole != null) ? "selected" : ""}`}><div className={`animation-line ${step >= 1 ? "active" : ""}`} /></div> */}
              <div className={`step ${step === 1 ? "selected" : ""}`}>
                <div className={`img-div ${step >= 1 ? "active" : ""}`}>
                  <img src={file} alt='template' />
                </div>
                <div className="text-div">
                  <h5>Template</h5>
                </div>
              </div>
              {/* <div className={`line ${step >= 2 ? "active" : (selectedResume != null && selectedTemplate != null && selectedRole != null) ? "selected" : ""}`}><div className={`animation-line ${step >= 2 ? "active" : ""}`} /></div> */}
              <div className={`step ${step === 2 ? "selected" : ""}`}>
                <div className={`img-div ${step >= 2 ? "active" : ""}`}>
                  <img src={file} alt='resume' />
                </div>
                <div className="text-div">
                  <h5>Resume</h5>
                </div>
              </div>
              {/* <div className={`line ${step >= 3 ? "active" : (selectedResume != null && selectedTemplate != null && selectedRole != null) ? "selected" : ""}`}><div className={`animation-line ${step >= 3 ? "active" : ""}`} /></div> */}
              <div className={`step ${step === 3 ? "selected" : ""}`}>
                <div className={`img-div ${step >= 3 ? "active" : ""}`}>
                  <img src={confirm} alt='resume' />
                </div>
                <div className="text-div">
                  <h5>Download</h5>
                </div>
              </div>
              {/* <div className={`line-final ${step >= 3 ? "active" : (selectedResume != null && selectedTemplate != null && selectedRole != null) ? "selected" : ""}`}><div className={`animation-line ${step >= 3 ? "active" : ""}`} style={{ animationDelay: "1.2s !important" }} /></div> */}
            </div>
          </div>
          <div className='card-div'>
            {step === 0 &&
              <RoleCard
                handleAddRole={handleAddRole}
                selectedRole={selectedRole}
                handleSelectedRoleChange={handleSelectedRoleChange}
                roles={roles}
                handleRefreshRoles={handleRefreshRoles}
                deleteRole={deleteRole}
                resetFormValues={resetFormValues}
                roleTitleShowError={roleTitleShowError}
                roleIndustryShowError={roleIndustryShowError}
                roleLocationShowError={roleLocationShowError}
                validateForm={validateForm}
                isLoading={rolesLoading}
              />
            }
            {step === 1 &&
              <TemplateCard
                handleSelectedTemplateChange={handleSelectedTemplateChange}
                templates={templates}
                selectedTemplate={selectedTemplate}
                handleRefreshTemplates={handleRefreshTemplates}
                selectedRole={selectedRole}
                deleteTemplate={deleteTemplate}
                isLoading={templatesLoading}
              />
            }
            {step === 2 &&
              <ResumeCard
                handleSelectedResumeChange={handleSelectedResumeChange}
                resumes={resumes}
                selectedResume={selectedResume}
                handleRefreshResumes={handleRefreshResumes}
                selectedRole={selectedRole}
                deleteResume={deleteResume}
                isLoading={resumesLoading}
                setDeletedResumesList={setDeletedResumesList}
              />
            }
            {step === 3 &&
              <ConfirmCard
                selectedTemplate={selectedTemplate}
                selectedResume={selectedResume}
                selectedRole={selectedRole}
              />
            }

            <Row fluid="true" className="button-container">
              <Col md="auto">
                {step > 0 && <button className="next" onClick={handlePreviousStepClick}>Back</button>}
              </Col>
              <Col md="auto">
                {step !== 3 &&
                  <button className={`button next 
                ${((!selectedRole && step === 0) ||
                      ((!selectedTemplate && step === 1) ||
                        ((selectedResume?.formatting_status !== FORMATTING_STATUS_COMPLETE && step === 2)))) ? "disabled" : ""}`}
                    disabled={(!selectedRole && step === 0) ||
                      (!selectedTemplate && step === 1) ||
                      ((selectedResume?.formatting_status !== FORMATTING_STATUS_COMPLETE && step === 2))}
                    onClick={handleNextStepClick}>Next</button>}
              </Col>
            </Row>
          </div>
        </div>
      </section >
    </Fade >
  );
}