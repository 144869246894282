import React, { useState } from 'react';

export function LoginFormViewer({
  login,
  toggleSignup,
  signup,
  email,
  handleEmailChange,
  password,
  handlePasswordChange,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  guardCheck,
  errors,
  isSignup,
  authenticating,
}) {

  return (
    <section className='login'>
      <div className={isSignup ? 'container sign-up-active' : 'container'}>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-left">
              <h2>Welcome Back!</h2>
              <button className="invert button" id="signIn" onClick={toggleSignup}>Sign In</button>
            </div>
            <div className="overlay-right">
              <h2>Don't Have an Account?</h2>
              <button className="invert button" id="signUp" onClick={toggleSignup}>Sign Up</button>
            </div>
          </div>
        </div >
        <div className="auth-blocks">
          <div className="sign-up-container">
            <form className="sign-up">
              <h2>Create Account</h2>
              <div className="form-control">
                <input type="first_name" name="first_name" id="first_name" placeholder="First Name" value={firstName} className="login-input" onChange={handleFirstNameChange} />
                <input type="last_name" name="last_name" id="last_name" placeholder="Last Name" className="login-input" onChange={handleLastNameChange} />
                <input type="email" name="email" id="email" placeholder="Email" value={email} className="login-input" onChange={handleEmailChange} />
                <input type="password" name="registerPassword" id="password" placeholder="Password" value={password} className="login-input" onChange={handlePasswordChange} />
              </div>
              <div className="form-control">
                <button onClick={signup} type="submit">
                  Sign Up
                </button>
              </div>
            </form >
          </div>
          <div className="sign-in-container">
            <form className="sign-in">
              {authenticating === false && < h2 > Sign In</h2>}
              {authenticating === true && <div className="loader"></div>}
              <div className="form-control">
                <input type="email" name="username" id="username" placeholder="Username" value={email} className="login-input" onChange={handleEmailChange} />
                <input type="password" name="password" id="password" placeholder="Password" value={password} className="login-input" onChange={handlePasswordChange} />
              </div>
              <div className="form-control">
                <button type="submit" onClick={login}>
                  Login
                </button>
              </div>
            </form >
          </div>
        </div>
      </div>
    </section >
  );
}