import React from 'react'
import Fade from 'react-reveal/Fade'

export default function Banner() {
  const data = require('../../../assets/lang/components/banner.json');

  return (
    <>
      <section className="banner">
        <div className="banner-background" />
        <Fade distance="40px" bottom>
          <div className="banner__inner">
            <h2>{data.title.main}</h2>
            <h3>{data.title.sub}</h3>
            <h4>{data.title.sub_secondary}</h4>
            <a href="/login" className="call-to-action">{data.call_to_action}</a>
          </div>
        </Fade>
      </section>
    </>
  )
}

