import React from "react";

export default function IconViewer(props) {
  const {
    children,
    onClick = () => { },
    disabled = false,
  } = props;

  const handleOnClick = (e) => {
    if (disabled === true) {
      return;
    }
    onClick(e);
  };

  return (
    <span onClick={handleOnClick}>
      {children}
    </span>
  )
}