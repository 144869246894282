import React from 'react'

export default function Footer(props) {
  return (
    <>
      <section className="footer">
        <div className="footer__inner">

        </div>
      </section>
    </>
  )
}


