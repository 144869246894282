import React from 'react'
import Fade from 'react-reveal/Fade'

export function VerifyEmail({
  verifyEmailToken,
  handleTokenChange,
  token
}) {
  //const data = require('../../../assets/lang/components/verifyEmail.json');

  return (
    <>
      <section className="verify-email">
        <Fade distance="40px" bottom>
          <div className="verify-email__inner">
            <form className="verify-email-form">
              <h2>Verify Account</h2>
              <h3>Paste the code sent to your email below</h3>
              <div className="form-control">
                <input type="email-code" name="email-code" id="email-code" placeholder="Paste Email Code Here" value={token} onChange={handleTokenChange} className="login-input" />
              </div>
              <div className="form-control">
                <button type="submit" onClick={verifyEmailToken}>
                  Verify
                </button>
              </div>
            </form >
          </div>
        </Fade>
      </section>
    </>
  )
}