import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import UploadFileModal from '../modals/uploadFileModal';
import TemplateStylingModal from '../modals/templateStylingModal';
import RefreshIcon from '@/components/icons/refreshIcon';
import bin from '@/assets/images/bin.svg';
import add from '@/assets/images/add.svg'
import edit from '@/assets/images/edit.svg'

export default function TemplateCard(props) {
  const {
    handleNextStepClick,
    handlePreviousStepClick,
    handleSelectedTemplateChange,
    templates = [],
    selectedTemplate,
    handleRefreshTemplates,
    selectedRole,
    deleteTemplate,
    isLoading
  } = props;

  const [attachment, setAttachment] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [template, setTemplate] = useState(null);
  const [stylingModalState, setStylingModalState] = useState(false);

  const handleAttachmentChange = (e) => {
    const selectedAttachment = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    setAttachment(selectedAttachment);
  };

  const handleSelectFile = (selected) => {
    handleSelectedTemplateChange(selected);
    setTemplate(selected);

  }

  const handleToggleModalState = () => {
    setModalState(!modalState);
  }

  const handleToggleTemplateStylingModal = () => {
    setStylingModalState(!stylingModalState);
  }

  const handleSetSelected = (selected) => {
    setTemplate(selected);
    handleSelectedTemplateChange(selected);
  }

  return (
    <section className="template">
      <div className="template__inner">
        <div className="card-heading">
          <div className="text-div">
            <h4>Select A Template <RefreshIcon onClick={handleRefreshTemplates} /></h4>
          </div>
        </div>
        <ul className="template-list">
          {isLoading === true && <li className="spinner"><div class="lds-dual-ring"></div></li>}
          {isLoading === false && templates.map((element, index) => (
            <li onClick={() => handleSelectFile(element)} key={index} className={selectedTemplate === element ? 'selected' : ''}>
              <div class="options">
                <button className="option-button" onClick={() => deleteTemplate(element)}>
                  <img className="bin" alt="Delete" src={bin} />
                </button>
                <button className="option-button" onClick={handleToggleTemplateStylingModal}>
                  <img className="bin" alt="Edit" src={edit} />
                </button>
              </div>
              <div class="template-name">{element.filename}</div>
              <div className="spinner-div"><div class="lds-dual-ring"></div></div>
            </li>
          ))}
        </ul>

        <div className="add-button-div">
          <button className="add-template" onClick={handleToggleModalState}><img src={add} alt="Add a New Role" /></button>
        </div>

        {modalState && <UploadFileModal
          handleToggleModalState={handleToggleModalState}
          parentModalState={modalState}
          stylingModalState={stylingModalState}
          handleSelectFile={handleSelectFile}
          type="template"
          handleRefresh={handleRefreshTemplates}
          selectedRole={selectedRole}
          isLoading={isLoading}
          file={template}
          handleToggleTemplateStylingModal={handleToggleTemplateStylingModal}
          handleSetSelected={handleSetSelected}
        >
        </UploadFileModal>}

        {stylingModalState && <TemplateStylingModal
          handleToggleTemplateStylingModal={handleToggleTemplateStylingModal}
          selectedTemplate={selectedTemplate}
          template={template}
        >
        </TemplateStylingModal>}
      </div>
    </section >
  )
}
